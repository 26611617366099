<template>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
        <!-- faq search section -->
        <section id="faq-search-filter">
            <b-card
                no-body
                class="faq-search"
                :style="{
          backgroundImage: `url(${require('@/assets/images/backgrounds/banner-faq.jpg')})`,
        }"
            >
                <b-card-body class="text-center">
                    <h2 class="text-white">Esclareça suas dúvidas</h2>

                    <!-- form -->
                    <b-form class="faq-search-input" @submit.prevent="">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                id="searchbar"
                                v-model="faqSearchQuery"
                                placeholder="Pergunte algo"
                                @change="getAjudas"
                            />
                        </b-input-group>
                    </b-form>
                    <!-- form -->
                </b-card-body>
            </b-card>
        </section>
        <!--/ faq search section -->

        <!-- frequently asked questions tabs pills -->
        <section id="faq-tabs" class="mb-3">
            <b-tabs
                vertical
                pills
                content-class="col-12 col-md-8 col-lg-9 col-xl-10"
                nav-wrapper-class="faq-navigation col-12 col-md-4 col-lg-3 col-xl-2"
                nav-class="nav-left"
            >
                <!-- categorias tab -->
                <b-tab
                    v-for="categoria in categorias"
                    :key="categoria.id_categoria"
                    :active="categoriaSelecionada == categoria.id_categoria"
                >
                    <!-- title -->
                    <template #title>
                        <feather-icon
                            v-if="categoria.url !== ''"
                            :icon="categoria.url"
                            size="18"
                            class="mr-1"
                            style="width: 50px !important;"
                        />
                        <span class="font-weight-bold">{{ categoria.titulo }}</span>
                    </template>

                    <FaqAjudaResumo
                        :selectedHelp="idSelectedHelp"
                        :options="categoria"
                    />
                </b-tab>
                <!--/ categorias tab -->
            </b-tabs>
        </section>
        <!--/ frequently asked questions tabs pills -->

        <!-- contact us -->
        <section class="faq-contact text-center bg-white p-2 pt-4">
            <b-row class="justify-content-center">
                <b-col lg="8" md="8" sm="12" class="my-2">
                    <h2>Ainda com dúvidas?</h2>
                    <p>
                        Não conseguiu achar uma resposta para as suas dúvidas no nosso
                        FAQ?<br />Entre em contato conosco, responderemos o mais breve
                        possível.
                    </p>

                    <SuporteDuvidasForm :FormularioDeDuvida="true" />
                </b-col>
            </b-row>
        </section>
        <!--/ contact us -->
    </b-overlay>
</template>

<script>
import {
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BOverlay,
} from "bootstrap-vue";
import FaqAjudaResumo from "./FaqAjudaResumo.vue";
import SuporteDuvidasForm from "@/views/components/custom/suporte-duvidas/SuporteDuvidasForm.vue";

export default {
    components: {
        BForm,
        BCard,
        BRow,
        BCol,
        BAvatar,
        BCardBody,
        BInputGroup,
        BFormInput,
        BCardText,
        BInputGroupPrepend,
        BTabs,
        BTab,
        BImg,
        FaqAjudaResumo,
        BLink,
        BOverlay,
        SuporteDuvidasForm,
    },
    data() {
        return {
            faqSearchQuery: "",
            faqData: {},
            categorias: {},
            categoriaSelecionada: null,
            loadingShow: true,
            idSelectedHelp: null,
        };
    },
    beforeMount() {
        this.categoriaSelecionada = this.$route.params.categoria;
        this.idSelectedHelp = this.$route.params.idAjuda;
        this.getAjudas();
    },
    methods: {
        getAjudas() {
            this.loadingShow = true;
            this.$http
                .get(this.$api.ajuda(), { params: { termoPesquisa: this.faqSearchQuery, categorizadas: true } })
                .then((res) => {
                    this.categorias = res.data.map(category => {
                        if(category.url === null || Object.is(category.url, null) || Object.is(category.url, undefined)){
                            category.url = '';
                        }
                        return category;
                    });
                    this.loadingShow = false;
                });
        }
    }
};
</script>

<style scopped lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";

#faq-tabs .nav-pills .nav-link.active,
#faq-tabs .nav-pills .show > .nav-link {
    background: rgb(240, 240, 240, 0);// #F5F5F5;
    color: $custom-blue;
    border: none;
    box-shadow: none;
    border-left: 2px solid;
    border-radius: 0;
}
.faq-navigation {
    height: auto;
    ul > li {
        display: block;
        width: 100%;
        word-break: break-word;
    }
}
.faq-search .faq-search-input .input-group {
    width: 70%;
}
</style>
