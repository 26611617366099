<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <validation-observer ref="SuporteDuvidasForm">
      <b-alert v-if="alert.show" show fade class="mb-2" variant="danger">
        <div class="custom-alert">
          <span>
            <BIconExclamationCircle />

            <template v-if="typeof alert.message === 'string'">
              {{ alert.message }}
            </template>
            <template v-else>
              <span v-for="(value, key) in alert.message" :key="key">
                {{ value[0] }}
              </span>
            </template>
          </span>
        </div>
      </b-alert>
      <b-form id="SuporteDuvidasForm">
        <b-row
          class="text-left"
          :class="FormularioDeDuvida == true ? 'd-none' : ''"
        >
          <b-col md="6" xl="6">
            <!-- nome -->
            <b-form-group label="Nome *" label-for="nome">
              <validation-provider
                #default="{ errors }"
                name="Seu nome completo"
                :rules="validacoes.nome"
              >
                <b-form-input
                  id="nome"
                  placeholder="Seu nome completo"
                  v-model="nome"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" xl="6">
            <!-- email -->
            <b-form-group label="E-mail *" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Seu e-mail"
                :rules="validacoes.email"
              >
                <b-form-input
                  id="email"
                  placeholder="Seu e-mail"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" xl="6">
            <!-- telefone -->
            <b-form-group label="Telefone" label-for="telefone">
              <vue-cleave
                id="telefone"
                v-model="telefone"
                :raw="true"
                :options="mascaraTelefone"
                class="form-control"
                placeholder="(99) 99999-9999"
                name="Telefone"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="6">
            <!-- empresa -->
            <b-form-group label="Empresa" label-for="empresa">
              <b-form-input
                id="empresa"
                placeholder="Nome da empresa"
                v-model="empresa"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="text-left">
          <b-col class="col-12 mx-auto">
            <!-- assunto -->
            <b-form-group label="Assunto *" label-for="assunto">
              <validation-provider
                #default="{ errors }"
                name="Assunto"
                :rules="validacoes.assunto"
              >
                <b-form-input
                  id="assunto"
                  :placeholder="placeholder.assunto"
                  v-model="assunto"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="col-12 mx-auto">
            <!-- uf -->
            <b-form-group label="Estado *" label-for="Estado">
              <validation-provider
                #default="{ errors }"
                name="Estado"
                :rules="validacoes.ufSelected"
              >
                <v-select
                  v-model="ufSelected"
                  :options="uf"
                  :reduce="(option) => option.uf"
                  variant="custom"
                  item-value="uf"
                  label="nome"
                  placeholder="Por favor, selecione um estado"
                  :state="errors.length > 0 ? false : null"
                  :clearable="false"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="col-12 mx-auto">
            <!-- mensagem -->
            <b-form-group label="Mensagem *" label-for="mensagem">
              <validation-provider
                #default="{ errors }"
                name="Assunto"
                :rules="validacoes.mensagem"
              >
                <b-form-textarea
                  id="mensagem"
                  :placeholder="placeholder.mensagem"
                  v-model="mensagem"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="col-12 mb-1 text-center">
            <b-button
              type="submit"
              variant="custom-blue"
              :disabled="bloquearEnvio"
              @click.prevent="enviarFormulario"
            >
              Enviar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <div id="modal-formulario"></div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormTextarea,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import VueCleave from "vue-cleave-component";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    VueCleave,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BAlert,
    BOverlay,
    vSelect,
  },
  props: {
    FormularioDeDuvida: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
        variant: "",
      },
      required,
      mascaraTelefone: {
        delimiters: ["(", ") ", "-"],
        blocks: [0, 2, 5, 4],
        numericOnly: true,
      },
      validacoes: {
        nome: "",
        email: "",
        assunto: "",
        mensagem: "",
        ufSelected: "",
      },
      placeholder: {
        assunto: "Assunto da mensagem",
        mensagem: "Como podemos te ajudar? :)",
      },
      tipoDeContato: "Suporte",
      nome: "",
      email: "",
      empresa: "",
      assunto: "",
      mensagem: "",
      telefone: "",
      uf: [],
      ufSelected: null,
      showLoading: true,
    };
  },
  async created() {
    await this.$http.get(this.$api.getEstados()).then(({ data }) => {
      this.uf = data;
      this.showLoading = false;
    });
  },
  mounted() {
    if (this.FormularioDeDuvida) {
      const { userData } = this.$store.state.sessions;

      this.placeholder.assunto = "Objetivo da sua mensagem";
      this.placeholder.mensagem = "Explique como podemos te ajudar :)";

      this.tipoDeContato = "Duvida";
      this.nome = userData.fullName;
      this.email = userData.email;
      this.empresa = userData.empresa.empresa_razao;
    }
  },
  methods: {
    limpaFormulario() {
      if (!this.FormularioDeDuvida) {
        this.nome = "";
        this.email = "";
        this.empresa = "";
        this.telefone = "";
      }

      this.assunto = "";
      this.mensagem = "";
      this.ufSelected = null;

      this.validacoes.nome = "";
      this.validacoes.email = "";
      this.validacoes.assunto = "";
      this.validacoes.mensagem = "";
      this.validacoes.ufSelected = "";

      this.$refs.SuporteDuvidasForm.reset();
    },
    mostrarModal(sucesso) {
      let title = sucesso
        ? "Mensagem enviada com sucesso"
        : "Mensagem não enviada";
      let text = sucesso
        ? "Assim que possível entraremos em contato com você!"
        : "Tente novamente mais tarde.";

      this.limpaFormulario();

      this.$swal({
        title: `<h3>${title}</h3>`,
        text: `${text}`,
        icon: sucesso ? "success" : "error",
        iconColor: sucesso ? "#36ba00" : "red",
        target: "#modal-formulario",
        customClass: {
          confirmButton: "btn btn-custom-blue",
        },
        buttonsStyling: false,
      });
    },
    errorHandling(response) {
      let message = response.data ? response.data.errors : response;

      this.showAlert(message, "danger");
    },
    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },
    enviarFormulario() {
      this.validacoes.nome = "required";
      this.validacoes.email = "required";
      this.validacoes.assunto = "required";
      this.validacoes.mensagem = "required";
      this.validacoes.ufSelected = "required";

      this.$refs.SuporteDuvidasForm.validate().then((success) => {
        if (success) {
          let dados = {
            tipo_de_contato: this.tipoDeContato,
            nome: this.nome,
            email: this.email,
            telefone: this.telefone,
            empresa: this.empresa,
            assunto: this.assunto,
            mensagem: this.mensagem,
            uf: this.ufSelected,
          };

          this.$http
            .post(this.$api.ajudaSendEmail(), dados)
            .then(() => {
              this.mostrarModal(true);
              this.alert.show = false;
            })
            .catch((error) => {
              if (error.response) {
                this.errorHandling(error.response);
              } else {
                this.errorHandling(
                  "Tempo de espera expirado. Tente novamente ou contate o suporte."
                );
              }
            });
        }
      });
    },
  },
  computed: {
    bloquearEnvio: function () {
      return !(
        this.nome.length > 0 &&
        this.email.length > 0 &&
        this.assunto.length > 0 &&
        this.mensagem.length > 0 &&
        this.ufSelected != null
      );
    },
  },
};
</script>

<style scoped>
#SuporteDuvidasForm textarea#mensagem {
  height: 190px;
}
#SuporteDuvidasForm .btn.btn-custom {
  width: 138px;
}
</style>
