<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-avatar rounded size="42" variant="custom-blue" class="mr-1">
        <feather-icon :icon="options.url" size="20" />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ options.titulo }}
        </h4>
      </div>
    </div>

    <!-- collapse -->
    <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
      <div v-if="options.ajudas.length > 0">
        <app-collapse-item
          v-for="ajuda in options.ajudas"
          :isVisible="ajuda.id_ajuda == selectedHelp"
          :key="ajuda.id_ajuda"
          :title="ajuda.topico"
        >
          <span v-html="ajuda.conteudo"></span>
        </app-collapse-item>
      </div>
      <div v-else>Nenhum registro encontrado.</div>
    </app-collapse>

    <!--/ collapse -->
  </div>
</template>

<script>
import { BAvatar, BLink } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BLink,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      default: null,
    },
    selectedHelp:{
      type: Number,
      default: null,
    }
  },
};
</script>